import React, { Component, createRef } from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { OnClickOutside, Search } from '@united-talent-agency/julius-frontend-components';
import { Icons } from '@united-talent-agency/components';

import * as colors from '../../../styles/colors';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { HEADER } from '../../../support/cypressTags';
import withFeatureFlagsHOC from '../../../hocs/withFeatureFlagsHOC';

const PopoverItem = ({ label, iconClass, onClick, styles }) => (
  <li className={styles.menuItem} onClick={onClick}>
    {/* TODO: remove, use Ant designs */}
    <i className={`${styles.menuIcon} fa fa-fw ${iconClass}`} aria-hidden="true" />
    {label}
  </li>
);

// don't try to refactor into functional, refs don't play nicely with functional components
class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.trigger = createRef();
  }
  render() {
    const { styles, showReleaseNotes, handleShowReleaseNotes } = this.props;

    const signOutOnClick = () => {
      localStorage.removeItem('uta.auth');
      // tracking
      // unset user
      // userId: null,
      // userName: null,
      // userEmail: null,
      window.location = '/login';
    };

    const popoverBottom = (
      <Popover
        data-cy={HEADER.MENU_CONTAINER}
        id="popover-positioned-bottom"
        positionTop="100"
        title={'Menu'}
      >
        {showReleaseNotes && (
          <ul className={styles.menuList}>
            <PopoverItem
              label="Release Notes"
              iconClass="fa-sticky-note"
              onClick={handleShowReleaseNotes}
              styles={styles}
            />
          </ul>
        )}
        <ul className={styles.menuList}>
          <PopoverItem
            label="Sign Out"
            iconClass="fa-sign-out"
            onClick={signOutOnClick}
            styles={styles}
          />
        </ul>
      </Popover>
    );

    return (
      <div data-cy={HEADER.MENU_OPENER} className={styles.userContent}>
        <div className={styles.imageColumn}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            delay={0}
            ref={this.trigger}
            onBlur={this.trigger?.hide}
            overlay={popoverBottom}
          >
            <OnClickOutside onClickOutside={this.trigger?.hide}>
              <Icons.HamburgerMenuIcon className={styles.userImage} />
            </OnClickOutside>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstLoad: true,
    };

    this.keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('/')]: (e) => {
          e.preventDefault();

          return this.searchInput && this.searchInput.focus();
        },
      })
    );
  }

  componentDidMount() {
    keyboard.addListener(this.keyboardListener);
  }

  componentWillUnmount() {
    keyboard.removeListener(this.keyboardListener);
  }

  searchBar(styles, noDebounce, search, navigate) {
    return (
      <div className={styles.search}>
        <Search
          noDebounce={noDebounce}
          value={search.query}
          onSubmit={(query) => {
            if (!query && this.props.onSearchChange) {
              this.props.onSearchChange(query);
              return;
            }
            if (navigate) {
              navigate({ ...search, query });
            }
          }}
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
          onChange={(value) => {
            if (this.props.onSearchChange) {
              this.props.onSearchChange(value);
            }
          }}
          inputRef={(searchInput) => {
            this.searchInput = searchInput;
            if (this.state.firstLoad) {
              this.searchInput.focus();
              this.setState({ firstLoad: false });
            }
          }}
          cyTag={HEADER.SEARCH_BOX}
        />
      </div>
    );
  }

  render() {
    const { styles, search, navigate, noDebounce, handleShowReleaseNotes, showReleaseNotes } =
      this.props;

    return (
      <div data-cy={HEADER.CONTAINER} className={styles.headerContainer}>
        <div className={styles.leftColumn} />
        <div className={styles.searchColumn}>
          {this.searchBar(styles, noDebounce, search, navigate)}
        </div>
        <div className={styles.userColumn}>
          <div className={styles.header}>
            <UserMenu
              handleShowReleaseNotes={handleShowReleaseNotes}
              showReleaseNotes={showReleaseNotes}
              styles={styles}
            />
          </div>
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    justifyContent: 'center',
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.background,
    },
  },
  menuList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  menuIcon: {
    marginRight: 10,
  },

  userContent: {
    marginLeft: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: 35,
    width: 35,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },

  multiselect: {
    display: 'flex',
    marginLeft: '10px',
    flex: 'auto',
    alignItems: 'center',
  },
  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: 10,
    marginTop: 3,
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.3,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  userColumn: {
    display: 'flex',
    flex: 0.2,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 5,
  },
});

const withState = connect((state) => {
  const { desk, user } = state;
  const { deskIds } = user;
  const { editCallTodo } = state.callForm;
  const { total, callTodos } = state.callTodo.calls;

  return { desk, deskIds, editCallTodo, total, callTodos };
});

export default withState(withStyles(withFeatureFlagsHOC(Header)));
