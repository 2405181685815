import React, { useState } from 'react';
import classnames from 'classnames';
import { styled } from 'react-free-style';

import { Dropdown, Input } from '@united-talent-agency/julius-frontend-components';
import { FILTERS } from '../../support/cypressTags';

const ESCAPE_KEY = 27;
const DOWN_KEY = 40;

const DropdownFilter = ({
  title,
  value,
  className,
  results,
  focus,
  styles,
  selectedResults,
  onCancel,
  onChange,
  onSelectStatus,
  onFocusChanged = () => {},
}) => {
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [focused, setFocused] = useState(false);
  const [keyword, setKeyword] = useState('');

  const onKeyDown = (event) => {
    const { keyCode } = event;

    if (keyCode === ESCAPE_KEY) {
      onCancel && onCancel();
    }
    if (keyCode === DOWN_KEY && !results) {
      onChange(value);
    }
  };

  const onFocus = (focused = true) => {
    setFocused(true);
    setKeyword('');
    onFocusChanged(focused);
  };

  let items = [],
    selectedItems = [];

  if (results) {
    items = results
      .filter((item) => item.toLowerCase().includes(keyword.toLowerCase()))
      .map((item) => {
        return {
          key: item,
          className: styles.item,
          onClick: () => {
            onSelectStatus(item);
          },
          content: (
            <div>
              <span className={styles.selectText}>{item}</span>
            </div>
          ),
        };
      });
  }

  if (selectedResults) {
    selectedItems = selectedResults.map((selectedItem) => {
      return {
        key: selectedItem.text,
        className: styles.selectedItem,
        content: (
          <div>
            <span className={styles.selectedItem}>{selectedItem.text}</span>
          </div>
        ),
      };
    });
  }

  const displayTitle = title || 'Status';

  return (
    <div className={classnames(styles.container, className)}>
      <Input
        title={displayTitle}
        focus={focus}
        onFocus={() => onFocus()}
        onKeyDown={(e) => onKeyDown(e)}
        onBlurClear
        onBlur={() => {
          if (!ignoreBlur) {
            onCancel && onCancel();
          }
          onFocus(false);
          setIgnoreBlur(false);
          setFocused(false);
        }}
        value={keyword}
        onChange={setKeyword}
        cyTag={`${displayTitle}-${FILTERS.FILTER_INPUT}`}
      />
      {focused && (
        <Dropdown
          items={items}
          selectedItems={selectedItems}
          style={{ maxHeight: 300, overflowY: 'auto' }}
          cyTag={`${displayTitle}-${FILTERS.FILTER_DROPDOWN}`}
        />
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    position: 'relative',
  },
  selectText: {
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1,
    padding: 10,
  },
  selectedItem: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    padding: 10,
  },
});

export default withStyles(DropdownFilter);
