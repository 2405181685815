import React from 'react';
import { sortBy } from 'lodash';
import { styled } from 'react-free-style';
import {
  PhoneOutlined,
  MailOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ICONS } from '../../support/cypressTags';

const isExtension = (num) => typeof num === 'string' && (num.length === 4 || num.length === 5);

const derivedContactInfo = (contactType, contactInfo) => {
  let metadata = {};
  switch (contactType) {
    case 'Email':
      metadata.icon = <MailOutlined data-cy={ICONS.EMAIL} />;
      metadata.href = `mailto:${contactInfo}`;
      break;
    case 'Mobile Phone':
      metadata.icon = <MobileOutlined data-cy={ICONS.PHONE} />;
      metadata.href = `tel:${contactInfo}`;
      break;
    case 'Office Phone':
    case 'Assistant Phone':
      metadata.icon = <PhoneOutlined data-cy={ICONS.LANDLINE} rotate={90} />;
      metadata.href = `tel:${contactInfo}`;
      metadata.isExtension = isExtension(contactInfo);
      break;
    case 'Home Phone':
      metadata.icon = <PhoneOutlined data-cy={ICONS.LANDLINE} rotate={90} />;
      metadata.href = `tel:${contactInfo}`;
      break;
    default:
      metadata.icon = <QuestionCircleOutlined />;
  }
  return metadata;
};

export const Contact = ({ contactType, contactInfo, styles }) => {
  const { href, icon, isExtension } = derivedContactInfo(contactType, contactInfo);

  return (
    <>
      {icon}
      <a href={href} className={styles.contactLink}>{`${
        isExtension && !contactInfo.includes('x') ? `x${contactInfo.replace(';', '')}` : contactInfo
      }`}</a>
    </>
  );
};

const CardContacts = ({ contacts, styles }) => {
  //const relevantContacts = contacts.filter(contactFilter).reduce(contactReducer, []);
  // phone first, then mobile, then email
  const orderedContacts = sortBy(contacts, ({ contactType }) => {
    switch (contactType) {
      case 'Email':
        return 3;
      case 'Mobile Phone':
        return 2;
      case 'Assistant Phone':
      case 'Office Phone':
      case 'Home Phone':
        return 1;
      default:
        return 4;
    }
  });
  return orderedContacts.map(({ contactType, contact }, index) => (
    <div key={index} className={styles.cardContactContainer}>
      <Contact contactType={contactType} contactInfo={contact} styles={styles} />
    </div>
  ));
};

const withStyles = styled({
  cardContactContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    marginBottom: '5px',
  },
  contactLink: { marginLeft: '10px', color: 'inherit', cursor: 'pointer' },
  extension: { marginLeft: '10px', color: 'inherit' },
});

export default withStyles(CardContacts);
