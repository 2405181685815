import React from 'react';
import Modal from 'react-modal';
import { styled } from 'react-free-style';

import ProfileInfo from '../profile-info/profile-info';
import ClientListContentLinked from './client-list-content-linked';
import ProfilePicture from '../profile-picture/profile-picture';
import { CLIENT_LIST } from '../../support/cypressTags';

const ClientListModal = (props) => {
  const { clientList, clientListPerson, isClientListModalOpen, onCloseModal, styles } = props;

  return (
    <Modal isOpen={isClientListModalOpen} style={clientListModalCustomStyles} ariaHideApp={false}>
      <div data-cy={CLIENT_LIST.CLIENT_LIST_MODAL}>
        {/* TODO: remove, use Ant designs */}
        <i className={`${styles.closeIcon} fa fa-times`} onClick={onCloseModal} />

        <ProfilePicture profilePic={clientListPerson.profile_pic} _styles={profilePictureStyles} />

        <div className={styles.profileInfoContainer}>
          <ProfileInfo person={clientListPerson} hideLocation />
        </div>

        <hr className="mx-2 mt-1 mb-0 p-0" />

        <ClientListContentLinked clientList={clientList} />

        <hr className="mb-2" />
        {clientList.length > 20 && (
          <p className="small text-center text-muted opacity-5" style={{ opacity: 0.6 }}>
            Scroll Down for More Clients
          </p>
        )}
      </div>
    </Modal>
  );
};

export const profilePictureStyles = {
  borderRadius: '50%',
  height: 75,
  width: 75,
  backgroundSize: 'cover',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const clientListModalCustomStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
  },
  content: {
    top: 375,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '280px',
    height: '650px',
    marginRight: '-50%',
    paddingTop: 10,
    paddingBottom: 10,
    color: '#212529',
    fontFamily: 'Gotham, sans-serif',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: '1px solid #ccc',
    backgroundColor: '#eee',
    overflow: 'visible',
  },
};

const withStyles = styled({
  // profile info
  profileInfoContainer: {
    fontSize: '10px',
    alignItems: 'center',
    textAlign: 'center',
  },

  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '5px',
    left: '5px',
    fontSize: '20px',
    color: 'black',
  },
  nameLink: {
    color: '#4A4A4A',
    fontSize: '12px',
    fontWeight: 300,
  },
  headshot: {
    borderRadius: '999px',
    width: '45px',
    height: '45px',
    border: `1px solid #DEDEDE`,
  },
  headshotImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: '999px',
  },
  redText: {
    color: 'red',
    fontWeight: 1000,
    fontSize: '15px',
    lineHeight: 1,
  },
});

export default withStyles(ClientListModal);
