import React, { useState } from 'react';
import { styled } from 'react-free-style';
import { Avatar } from '@united-talent-agency/julius-frontend-components';
import { RightOutlined } from '@ant-design/icons';
import {
  fromStringToE164,
  fromE164ToDisplay,
  ExpandableImage,
} from '@united-talent-agency/components';
import CardContacts from './card-contacts';
import { EMPLOYEE_CARD } from '../../support/cypressTags';
import { useFlags } from 'launchdarkly-react-client-sdk';

const allowedPronouns = [
  'She/Her',
  'He/Him',
  'They/Them',
  'Any/All Pronouns',
  'He/They',
  'She/They',
];

export const AuxillaryPersonDisplay = ({ onClick, styles, assistantEdge }) => {
  const userRegion = navigator.language.split('-')[1];
  const { person, email } = assistantEdge;
  const { contacts, DID } = person;
  const initials = person.initials ? `(${person.initials})` : '';
  // const email = (contacts || []).find((c)=>c.contactType === 'Email' && c.description !== 'System Email')
  const extension = (contacts || []).find(
    (c) => c.contact.charAt(0) === 'x' || c.contact.charAt(0) === ';'
  );
  return (
    <div className={styles.auxillarySection} data-cy={EMPLOYEE_CARD.AUXILLARY_PERSON}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ fontSize: '16px', fontWeight: 600 }}>{`${person.name} ${initials}`}</div>
        {email && (
          <div style={{ minWidth: '200px', wordBreak: 'break-all' }}>
            <a href={`mailto:${email}`} style={{ color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
              {email}
            </a>
          </div>
        )}
        {extension && (
          <div style={{ color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            x{extension.contact.replace('x', '').replace(';', '')}
          </div>
        )}
        {DID && (
          <div>
            <a href={`tel:${DID}`} style={{ color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
              {fromE164ToDisplay(fromStringToE164(DID), userRegion, ' x')}
            </a>
          </div>
        )}
      </div>
      <div
        className={styles.auxillaryAvatar}
        onClick={onClick}
        data-cy={EMPLOYEE_CARD.AUXILLARY_PERSON_PHOTO}
        style={{
          margin: '15px',
          border: '1px solid lightgrey',
          backgroundImage: `url(${person.profile_pic || Avatar})`,
          backgroundPosition: `center`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
        }}
      />
    </div>
  );
};

export const CardFace = ({
  assistants = [],
  assistantTo = [],
  styles,
  person,
  enableClientList,
  front,
  onClickClientList,
  handleFlipCard,
  setFlipped,
  setPerson,
}) => {
  const { featurePronoun = false } = useFlags();
  const [expanded, setExpanded] = useState(false);
  const hasClients =
    (person?.clientCounts?.people ?? 0) > 0 || (person?.clientCounts?.groups ?? 0) > 0;

  return (
    <div className={styles.cardFaceContainer}>
      <div
        data-cy={EMPLOYEE_CARD.EMPLOYEE_PHOTO}
        className={styles.userImage}
        style={{
          opacity: person.profile_pic ? 1 : 0.75,
          backgroundImage: `url(${person.profile_pic || Avatar})`,
          backgroundPosition: `center`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          border: '1px solid lightgrey',
          cursor: person.profile_pic ? 'pointer' : 'default',
        }}
        onClick={() => {
          if (person.profile_pic) {
            setExpanded(true);
          }
        }}
      />
      <div data-cy={EMPLOYEE_CARD.EMPLOYEE_INFO} style={{ flex: 0.85 }}>
        <a className={styles.nameLink} href={person.url ? person.url : '/profile/' + person._id}>
          {person.name + (person.initials ? ' (' + person.initials + ')' : '')}
        </a>
        {featurePronoun && !person.pronounHidden && allowedPronouns.includes(person.pronoun) && (
          <div
            data-cy={EMPLOYEE_CARD.EMPLOYEE_PRONOUN}
            style={{ fontSize: '16px', color: 'rgba(0,0,0,0.75)', marginTop: '3px' }}
          >
            {person.pronoun}
          </div>
        )}
        <div style={{ fontSize: '18px', fontWeight: 600, marginTop: '6px' }}>
          {person.department || ''}
        </div>
        <div style={{ fontSize: '18px', marginTop: '3px' }}>{person.title}</div>
        <div style={{ fontSize: '16px', color: 'rgba(0,0,0,0.75)', marginTop: '3px' }}>
          {(person.location ? person.location.split(' Office')[0] : '') +
            (person.office ? ` - ${person.office}` : '')}
        </div>
        {hasClients && enableClientList && (
          <div
            onClick={() => onClickClientList(person)}
            className={styles.clientList}
            data-cy={EMPLOYEE_CARD.CLIENT_LIST_BUTTON}
          >
            Client List
            <RightOutlined style={{ width: '13px', height: '13px' }} />
          </div>
        )}
        <div className={styles.contacts}>
          <CardContacts person={person} contacts={person.contacts} styles={styles} />
        </div>
      </div>
      <div className={styles.asstContainer}>
        {assistantTo.filter((assistantEdge) => !!assistantEdge?.person?.name).length > 0 && (
          <div>
            <div className={styles.auxillaryHeader}>Assistant To</div>
            {assistantTo.map((assistantEdge) => (
              <div key={assistantEdge.person._id}>
                <AuxillaryPersonDisplay
                  styles={styles}
                  assistantEdge={assistantEdge}
                  onClick={async () => {
                    setFlipped(front);
                    const mgr = await handleFlipCard(assistantEdge.person._id);
                    setPerson(mgr);
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {assistants.filter((assistantEdge) => !!assistantEdge?.person?.name).length > 0 && (
          <div>
            <div className={styles.auxillaryHeader}>Assistant</div>
            {assistants.map((assistantEdge) => (
              <div key={assistantEdge.person._id}>
                <AuxillaryPersonDisplay
                  isAssistant
                  styles={styles}
                  assistantEdge={assistantEdge}
                  onClick={async () => {
                    setFlipped(front);
                    const mgr = await handleFlipCard(assistantEdge.person._id);
                    setPerson(mgr);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <ExpandableImage
        expanded={expanded}
        image={person.profile_pic}
        onClick={() => setExpanded(false)}
        customStyles={{ width: 800, height: 800, borderRadius: '50%' }}
      />
    </div>
  );
};

const withStyles = styled({
  cardFaceContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr',
    alignItems: 'center',
    minHeight: '300px',
    lineHeight: '1.2',
  },
  asstContainer: {
    justifySelf: 'start',
    maxWidth: '100%',
  },
  clientList: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '6rem',
    fontWeight: 600,
    alignItems: 'center',
    marginTop: '3px',
  },
  contacts: {
    marginTop: '10px',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'span:nth-child(3)': {
      fontSize: '12px',
      marginLeft: '-30px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nameLink: { fontSize: '24px', color: 'black', fontWeight: 700 },
  userImage: {
    borderRadius: '50%',
    height: 150,
    width: 150,
    minWidth: 150,
    backgroundSize: 'cover',
    margin: '0 15px',
    padding: '10px',
  },
  auxillarySection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    fontSize: '14px',
    justifyContent: 'start',
  },
  auxillaryHeader: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px',
    marginTop: '10px',
  },
  auxillaryAvatar: {
    borderRadius: '50%',
    height: 50,
    width: 50,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
});
export default withStyles(CardFace);
