import React from 'react';
import { styled } from 'react-free-style';

import { UTA_CONTACTS } from '../../support/uta-contacts';
import { lightText } from '../../styles/colors';

const Contacts = ({ styles }) =>
  UTA_CONTACTS.map((contact, i) => (
    <div className="row" key={i}>
      <div className="mx-auto row">
        {!contact.name && <p className="my-0 text-left">&nbsp;</p>}
        <p className={`my-0 text-left ${styles.contactName}`}>{contact.name}</p>
        <p className={`my-0 text-right ${styles.contact}`}>{contact.contact}</p>
      </div>
    </div>
  ));

const withStyles = styled({
  contactName: {
    color: 'black',
    fontWeight: 300,
    fontSize: 16,
    width: 270,
  },
  contact: {
    color: lightText,
    fontWeight: 300,
    fontSize: 16,
    width: 165,
  },
});

export default withStyles(Contacts);
