import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'employees.unitedtalent.com': 'prod',
  'employees.staging.unitedtalent.com': 'staging',
  'employees.qa.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'employees-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: '43717f33-45ea-45e0-8c84-109339b5188c',
      clientToken: 'pub305df3b3871bf706f4ec5854e461b51f',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
