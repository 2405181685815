import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const withFeatureFlagsHOC = (Component) => {
  const Wrapper = (props) => {
    const featureFlags = useFlags();
    return <Component {...featureFlags} {...props} />;
  };

  return Wrapper;
};

export default withFeatureFlagsHOC;
