import React from 'react';
import { styled } from 'react-free-style';
import { CLIENT_LIST } from '../../support/cypressTags';

const ProfileInfo = (props) => {
  const { person, styles, hideLocation } = props;
  const compactStyles = hideLocation && { fontSize: 12 };
  return (
    <div>
      <div className={styles.nameLine}>
        <a
          className={styles.nameLink}
          href={person.url ? person.url : '/profile/' + person._id}
          data-cy={CLIENT_LIST.PROFILE_INFO_NAME}
        >
          {person.name + (person.initials ? ' (' + person.initials + ')' : '')}
        </a>
      </div>
      <div className={styles.title} style={compactStyles}>
        {person.title}
      </div>
      <div className={styles.info} style={compactStyles}>
        {person.department || ''}
      </div>
      {!hideLocation && (
        <div className={styles.info}>
          {(person.location ? person.location.split(' Office')[0] : '') +
            (person.office ? ` - ${person.office}` : '')}
        </div>
      )}
    </div>
  );
};

const withStyles = styled({
  nameLine: {
    fontSize: '14px',
    width: '97%',
  },
  nameLink: {
    fontSize: '14px',
    color: 'black',
  },
  info: {
    fontSize: '10px',
    fontWeight: 300,
    width: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontSize: '10px',
    fontWeight: 300,
    width: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(ProfileInfo);
