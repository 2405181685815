import React from 'react';
import { styled } from 'react-free-style';

import { Avatar } from '@united-talent-agency/julius-frontend-components';

const ProfilePicture = ({ _styles = {}, profilePic, styles, onClick = () => {} }) => {
  const backgroundImage = `url(${profilePic || Avatar})`;
  const opacity = profilePic ? 1 : 0.75;
  const customStyles = { ..._styles, backgroundImage, opacity };

  return <div className={styles.userImage} style={customStyles} onClick={onClick} />;
};

const withStyles = styled({
  userImage: {
    borderRadius: '50%',
    height: 125,
    width: 125,
    backgroundSize: 'cover',
    marginLeft: '75px',
    marginTop: '10px',
    marginBottom: '3px',
    cursor: 'pointer',
  },
});

export default withStyles(ProfilePicture);
