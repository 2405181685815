import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'react-free-style';
import { debounce } from 'lodash';

import { PatchNotes } from '@united-talent-agency/julius-frontend-components';
import {
  getEmployees,
  getEmployeeDepartments,
  getEmployeePeopleClients,
} from '@united-talent-agency/julius-frontend-store';
import { FilterPanel, Spinner, Branding } from '@united-talent-agency/components';
import { datadogRum } from '@datadog/browser-rum';

import Card from '../../components/card/card';
import ClientListModal from '../../components/client-list-modal/client-list-modal';
import ContactsTable from '../../components/contacts-table/contacts-table';
import DropdownFilter from '../../components/dropdown-filter/dropdown-filter';
import employeeSymbol from '../../assets/logo/employee_symbol_200x200.png';
import { EMPLOYEES_LIST, FILTERS } from '../../support/cypressTags';

import Header from '../shared/header';
import { useDebouncedCallback } from 'use-debounce';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;
const PAGE_SIZE = 20;
const HEADER_PAGE_MORE = 'Pagination-More';
const HEADER_PAGE_TOTAL = 'Pagination-Total';
const FILTER_OPTIONS = {
  Title: {
    targetIsArray: true,
    options: ['Agent', 'Assistant', 'Board Member', 'Coordinator', 'Intern', 'Partner', 'Trainee'],
  },
  Location: {
    targetIsArray: true,
    options: ['Los Angeles', 'Nashville', 'New York', 'London', 'Atlanta'],
  },
  Departments: {
    targetIsArray: true,
    options: [],
  },
};

for (const key in FILTER_OPTIONS) {
  if (FILTER_OPTIONS[key].targetIsArray) {
    FILTER_OPTIONS[key].options.sort();
  }
}

const Employee = ({ styles }) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState({ query: '' });
  const [isClientListModalOpen, setIsClientListModalOpen] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientListPerson, setClientListPerson] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [tags, setTags] = useState({});
  const [showTags, setShowTags] = useState({});

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const activeGroups = Object.keys(tags)
    .filter((tag) => {
      return tags[tag].items.length > 0;
    })
    .map((tag) => ({
      ...tags[tag],
      text: tag,
    }));

  useEffect(() => {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }

    window
      .fetch('https://projectjulius.blob.core.windows.net/notes/employee.json', {
        mode: 'cors',
      })
      .then(async (response) => {
        const notes = await response.json();
        setNotes(notes);
      });

    dispatch(getEmployeeDepartments()).then((result) => {
      setDepartments(result.body);
    });
  }, [dispatch]);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user) {
      if (user.azure_id && !DATA_DOG_SET) {
        datadogRum.setUser({
          id: user.azure_id,
          name: `${user.last_name}, ${user.first_name}`,
          email: user.email,
        });
        datadogRum.startSessionReplayRecording();
        DATA_DOG_SET = true;
      }

      if (user._id) {
        // tracking
        // identify user
        // userId: user._id,
        // userName: `${user.last_name}, ${user.first_name}`,
        // userEmail: user.email,
      }
    }
  }, [user]);

  const debouncedSearch = useDebouncedCallback(() => {
    // tracking searched employees
    dispatch(getEmployees({ all: true, groups: activeGroups }, 0, PAGE_SIZE))
      .then((result) => {
        const hasMore = result.headers.get(HEADER_PAGE_MORE) === true.toString() ? true : false;
        const total = parseInt(result.headers.get(HEADER_PAGE_TOTAL));

        if (result?.body?.error) {
          throw result?.body?.error;
        }

        if (Array.isArray(result?.body)) {
          setEmployees(result.body);
        }

        setIsLoading(false);
        setHasMore(hasMore);
        setPage(0);
        setTotal(total);
      })
      .catch((err) => {
        console.error(err);
        setEmployees([]);
        setIsLoading(false);
        setHasMore(false);
        setPage(0);
        setTotal(0);
      });
  }, 500);

  useEffect(() => {
    if (isLoading) {
      if (activeGroups.length === 0) {
        setEmployees([]);
        setIsLoading(false);
        setHasMore(false);
        setPage(0);
      } else {
        debouncedSearch();
      }
    }
  }, [activeGroups.length, debouncedSearch, isLoading]);

  const closeModal = () => {
    setIsClientListModalOpen(false);
    setClientList([]);
    setClientListPerson(null);
  };

  const scrollDown = () => {
    const newPage = page + 1;
    const skip = newPage * PAGE_SIZE;
    if (!hasMore) {
      return;
    }

    return dispatch(getEmployees({ all: true, groups: activeGroups }, skip, PAGE_SIZE))
      .then((result) => {
        const hasMore = result.headers.get(HEADER_PAGE_MORE) === true.toString() ? true : false;
        setEmployees(employees.concat(result.body));
        setIsLoading(false);
        setHasMore(hasMore);
        setPage(newPage);
      })
      .catch((err) => {
        console.error(err);
        setEmployees([]);
        setIsLoading(false);
        setHasMore(false);
        setPage(0);
        setTotal(0);
      });
  };

  const selectFilter = (filterKey, value) => {
    setTags((oldState) => ({
      ...oldState,
      [filterKey]: {
        ...oldState[filterKey],
        items: [...(oldState[filterKey]?.items || []), { include: true, text: value }],
      },
    }));
    setIsLoading(true);
  };

  const removeFilter = (filterKey, value) => {
    setTags((oldState) => ({
      ...oldState,
      [filterKey]: {
        ...oldState[filterKey],
        items: oldState[filterKey].items.filter((tag) => tag !== value),
      },
    }));
    setIsLoading(true);
  };

  FILTER_OPTIONS.Departments = {
    targetIsArray: true,
    options: departments,
  };

  return (
    <div className={styles.container}>
      <Branding text="Employees" symbol={employeeSymbol} showBanner={SHOW_BANNER} />
      <Header
        search={search}
        onSearchChange={(value) => {
          setSearch({
            ...search,
            query: value,
          });
          const noText = !value || value.trim() === '';
          if (noText && tags['Name']) {
            //remove group
            setTags((oldState) => ({
              ...oldState,
              Name: { items: [] },
            }));
            setIsLoading(true);
          } else if (!noText) {
            //add group
            setTags((oldState) => ({
              ...oldState,
              Name: {
                items: [value],
              },
            }));
            setIsLoading(true);
          }
        }}
        handleShowReleaseNotes={() => {
          localStorage.setItem('updateCount', notes.length);
          setIsNotesOpen(!isNotesOpen);
        }}
      />
      <div className={styles.gridContainer}>
        <div style={{ float: 'left', width: '20%', marginTop: 20 }}>
          <h5>Filters</h5>

          {Object.keys(FILTER_OPTIONS).map((filterKey) => (
            <div key={filterKey} style={{ marginTop: '5px' }}>
              <DropdownFilter
                title={filterKey}
                results={FILTER_OPTIONS[filterKey].options}
                selectedResults={tags[filterKey]?.items || []}
                onSelectStatus={(value) => selectFilter(filterKey, value)}
                onFocusChanged={(focused) => setShowTags({ ...showTags, [filterKey]: !focused })}
              />

              {showTags[filterKey] &&
                tags[filterKey] &&
                tags[filterKey].items.length > 0 &&
                tags[filterKey].items.map((tag, i) => (
                  <div
                    key={i}
                    data-cy={FILTERS.SELECTED_FILTER}
                    style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}
                  >
                    <span
                      onClick={() => removeFilter(filterKey, tag)}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fa fa-check-square fa-fw" />
                    </span>
                    {tag.text}
                  </div>
                ))}
            </div>
          ))}
        </div>
        <div style={{ float: 'left', padding: 16, width: '80%' }}>
          {activeGroups.length > 0 && (
            <div style={{ width: '100%' }}>
              <div
                style={{ paddingTop: 6, paddingLeft: 10 }}
                data-cy={EMPLOYEES_LIST.SEARCH_RESULT}
              >
                {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                {total !== 1 ? 'Results' : 'Result'}
              </div>
              <div style={{ float: 'left', paddingTop: 4, paddingLeft: 4 }}>
                <button
                  label="reset"
                  className={styles.resetButton}
                  onClick={() => {
                    setEmployees([]);
                    setIsLoading(false);
                    setHasMore(false);
                    setPage(0);
                    setTags({});
                    setSearch({ query: '' });
                  }}
                  data-cy={EMPLOYEES_LIST.RESET_BUTTON}
                >
                  Reset
                </button>
              </div>
              <div data-cy={EMPLOYEES_LIST.SELECTED_FILTER_CHIPS} style={{ float: 'left' }}>
                <FilterPanel
                  filters={FILTER_OPTIONS}
                  groups={activeGroups}
                  onGroupsChanged={(value) => {
                    const newGroups = JSON.parse(JSON.stringify(value));
                    let newTags = {};
                    for (let group of newGroups) {
                      if (group.items.length > 0) {
                        newTags = {
                          ...newTags,
                          [group.text]: { ...group },
                        };
                      }
                    }
                    setTags(newTags);
                    setIsLoading(true);
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ clear: 'left' }} data-cy={EMPLOYEES_LIST.LIST_CONTAINER}>
            <InfiniteScroll
              pageStart={page}
              initialLoad={false}
              loadMore={debounce(scrollDown, 300)}
              loader={<div className="loader" key={0} />}
              hasMore={hasMore}
            >
              {/* splash screen */}

              {!isLoading && (!employees || employees.length === 0) && (
                <div className={styles.searchResults}>
                  <ContactsTable />
                </div>
              )}

              {/* loading */}
              {isLoading && employees.length === 0 && (
                <div className={styles.spinner}>
                  <Spinner size={60} />
                </div>
              )}

              {/* results */}
              {(!isLoading || (isLoading && (hasMore || page > 0))) &&
                employees &&
                employees?.map((employee) => {
                  return (
                    <Card
                      key={employee._id}
                      person={employee}
                      dispatch={dispatch}
                      onClickClientList={(clientListPerson) => {
                        dispatch(getEmployeePeopleClients(clientListPerson._id)).then((result) => {
                          setClientListPerson(clientListPerson);
                          setClientList(result.body);
                          setIsClientListModalOpen(!isClientListModalOpen);
                        });
                      }}
                    />
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <PatchNotes
        isOpen={isNotesOpen}
        notes={notes}
        isEmployeeApp
        closeModal={() => {
          setIsNotesOpen(!isNotesOpen);
        }}
      />
      {clientListPerson && (
        <ClientListModal
          styles={styles}
          isClientListModalOpen={isClientListModalOpen}
          clientList={clientList}
          clientListPerson={clientListPerson}
          onCloseModal={closeModal}
          user={user}
        />
      )}
    </div>
  );
};

const withStyles = styled({
  searchResults: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
    fontWeight: '700',
    fontSize: 20,
  },
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    margin: '0 auto',
    alignItems: 'center',
  },
  search: {
    display: 'flex',
    flex: '1 1 30%',
    alignItems: 'center',
  },

  // loading dialogue

  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 5,
  },
  resetButton: {
    marginTop: '0.5em',
    fontSize: '80%',
    color: 'gray',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
});

export default withStyles(Employee);
