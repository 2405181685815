import React, { Component } from 'react';

import Contacts from '../contacts/contacts';
import { CONTACT_TABLE } from '../../support/cypressTags';

import Logo from '../../assets/logo/frame-black-600x600.png';

class ContactsTable extends Component {
  render() {
    return (
      <div data-cy={CONTACT_TABLE.CONTACT_TABLE}>
        <div className="mb-4 pt-3 column text-center">
          <img src={Logo} height={150} alt="UTA" />
        </div>
        <Contacts />
      </div>
    );
  }
}

export default ContactsTable;
