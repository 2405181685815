import React, { Component } from 'react';
import { styled } from 'react-free-style';
import ReactCardFlip from 'react-card-flip';
import { getEmployee } from '@united-talent-agency/julius-frontend-store';

import CardFace from './card-face';
import { fromStringToE164, fromE164ToDisplay } from '@united-talent-agency/components';
import { EMPLOYEE_CARD } from '../../support/cypressTags';

const handleFlip = (dispatch) => async (personId) => {
  const results = await dispatch(getEmployee(personId));
  return results && results.body;
  // return;
};

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: this.props.person,
      flipped: false,
    };
  }

  render() {
    const { styles, dispatch, onClickClientList } = this.props;
    const { person, flipped } = this.state;
    const userRegion = navigator.language.split('-')[1];
    const { assistants, assistantTo } = person;

    person.contacts =
      person.contacts &&
      person.contacts.map((contact) => {
        if (contact.contactType.includes('Phone')) {
          contact.contact = fromE164ToDisplay(fromStringToE164(contact.contact), userRegion, ' x');
        }
        return contact;
      });

    return (
      <div data-cy={EMPLOYEE_CARD.EMPLOYEE_CARD}>
        <ReactCardFlip
          isFlipped={flipped}
          flipDirection="vertical"
          containerStyle={{
            minWidth: 1000,
            maxWidth: 1600,
            verticalAlign: 'top',
          }}
        >
          <div data-cy={EMPLOYEE_CARD.EMPLOYEE_CARD_FACE} className={styles.cardFace}>
            <CardFace
              front
              enableClientList
              assistants={assistants}
              assistantTo={assistantTo}
              person={person}
              onClickClientList={onClickClientList}
              setFlipped={(newState) => this.setState({ flipped: newState })}
              setPerson={(newState) => this.setState({ person: newState })}
              handleFlipCard={handleFlip(dispatch)}
            />
          </div>
          <div data-cy={EMPLOYEE_CARD.EMPLOYEE_CARD_FACE} className={styles.cardFace}>
            <CardFace
              enableClientList
              person={person}
              assistants={assistants}
              assistantTo={assistantTo}
              onClickClientList={onClickClientList}
              setFlipped={(newState) => this.setState({ flipped: newState })}
              setPerson={(newState) => this.setState({ person: newState })}
              handleFlipCard={handleFlip(dispatch)}
            />
          </div>
        </ReactCardFlip>
      </div>
    );
  }
}

const withStyles = styled({
  cardFace: {
    width: '80%',
    backgroundColor: '#eee',
    padding: 5,
    margin: '10px',
    border: '1px solid #ccc',
  },
});
export default withStyles(Card);
