import React from 'react';
import _ from 'lodash';
import { styled } from 'react-free-style';

import { VerificationIcon } from '@united-talent-agency/components';
import { profileUrl, companyUrl } from '../../support/urls';
import { CLIENT_LIST } from '../../support/cypressTags';

const ClientListContentLinked = (props) => {
  const { clientList, styles } = props;

  const peopleList = _.sortBy(
    clientList?.filter((relationship) => !!relationship.person),
    (relationship) => relationship.person.name
  );
  const groupList = _.sortBy(
    clientList?.filter((relationship) => !!relationship.group),
    (relationship) => relationship.group.name
  );

  return (
    <div className="mt-2 mx-auto" style={{ height: 435, maxHeight: 435, overflow: 'scroll' }}>
      {!!peopleList?.length && <div>Client Person:</div>}
      {peopleList?.map((relationship) => {
        const client = relationship.person;
        const showVerifiedIcon = client.verifiedByName && client.verifiedOn;

        return (
          <p className="my-0 mx-3" key={client._id}>
            <a
              className={`${styles.nameLink}`}
              href={`${profileUrl}/${client._id}`}
              target="_blank"
              rel="noopener noreferrer"
              data-cy={CLIENT_LIST.CLIENT_NAME}
            >
              {client.name}
              {/point/i.test(relationship.type) ? <span className={styles.redText}>*</span> : null}

              {showVerifiedIcon && (
                <div style={{ marginLeft: '5px' }}>
                  <VerificationIcon
                    verifiedBy={client.verifiedByName}
                    verifiedOn={client.verifiedOn}
                    identifier={client._id}
                    includeExplanation
                  />
                </div>
              )}
            </a>
          </p>
        );
      })}
      {!!groupList?.length && <div className="mt-2">Client Company:</div>}
      {groupList?.map((relationship) => {
        const client = relationship.group;

        return (
          <p className="my-0 mx-3" key={client._id}>
            <a
              className={`${styles.nameLink}`}
              href={`${companyUrl}/${client._id}`}
              target="_blank"
              rel="noopener noreferrer"
              data-cy={CLIENT_LIST.CLIENT_NAME}
            >
              {client.name}
            </a>
          </p>
        );
      })}
    </div>
  );
};

const withStyles = styled({
  nameLink: {
    color: '#4A4A4A',
    fontSize: '12px',
    fontWeight: 300,
    display: 'flex',
  },

  redText: {
    color: 'red',
    fontWeight: 1000,
    fontSize: '15px',
    lineHeight: 1,
  },
});

export default withStyles(ClientListContentLinked);
