export const UTA_CONTACTS = [
  {
    name: 'Beverly Hills',
    contact: '(310) 273-6700',
  },
  {
    name: 'Foothill',
    contact: '(310) 273-6700',
  },
  {
    name: 'Bungalows',
    contact: '(323) 271-0250',
  },
  {
    name: 'New York',
    contact: '(212) 659-2600',
  },
  {
    name: 'Nashville',
    contact: '(615) 564-2580',
  },
  {
    name: 'London',
    contact: '+44 (207) 278-3331',
  },
  {
    name: 'Atlanta',
    contact: '(470) 468-5899',
  },
  {
    name: 'UTA Artist Space',
    contact: '(310) 579-9850',
  },
  {
    name: 'DBA West',
    contact: '(323) 602-0856',
  },
  {
    name: 'DBA East',
    contact: '(212) 366-4566',
  },
  {
    name: '',
    contact: '',
  },
  {
    name: 'Client Info',
    contact: 'x2299',
  },
  {
    name: 'Job List',
    contact: 'x2129',
  },
  {
    name: 'Beverly Hills Mailroom',
    contact: 'x2045',
  },
  {
    name: 'New York Mailroom',
    contact: 'x2863',
  },
  {
    name: 'Messenger Service',
    contact: 'x2133',
  },
  {
    name: 'Reception: Garden',
    contact: 'x4875 / x4879',
  },
  {
    name: 'Reception: Plaza',
    contact: 'x4880 / x4881',
  },
  {
    name: 'Security (East Bldg): 6a-10p',
    contact: '(310) 916-7603',
  },
  {
    name: 'Security (West Bldg): After Hours',
    contact: '(323) 627-3112',
  },
  {
    name: 'Security: Bungalows',
    contact: '(310) 623-9808',
  },
  {
    name: 'Service Desk',
    contact: '(310) 579-9990 / x3900',
  },
  {
    name: 'UTA U',
    contact: 'x3056',
  },
  {
    name: 'Valet: Garden',
    contact: 'x2190',
  },
  {
    name: 'Valet: Level P1',
    contact: 'x4762',
  },
];
